import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import TeacherListComponent from './TeacherListComponent';
import TeacherCreateComponent from './TeacherCreateComponent';
import TeacherDetailComponent from './TeacherDetailComponent';
import './teacher.scss';

const TeacherPage = () => {

    return <>
        <div className="ProfileScreen-container">
            <div className="ProfileScreen-mainContent">
                <p className="ProfileScreen-title">Teacher Management</p>
                <Routes>
                    <Route path="/" element={<TeacherListComponent />} />
                    <Route path="/create" element={<TeacherCreateComponent />} />
                    <Route path="/:id" element={<TeacherDetailComponent />} />
                </Routes>
            </div>
        </div>
    </>;
};

export default TeacherPage;