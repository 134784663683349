import "./App.scss";

import ReactGA from "react-ga4";

import axios from "axios";

import React, {useEffect, useState} from "react";
import {Router, Routes, Route, Navigate, BrowserRouter} from "react-router-dom";
import { createBrowserHistory } from "history";

import serverUrl from "./config.js";
import {isLoggedIn, getAccessToken} from "./api/security.js";
import {doAuthenticatedRequest, updateAccessToken} from "./api/requests.js";
import {requestSubscription} from "./api/userRequests.js";
import {handleError} from "./api/errorHandling.js";
import loadingSpinner from "./assets/images/loading-spinner.gif";

import RegisterScreenEmail from "./pages/auth/registerscreen/RegisterScreenEmail.js";
import LoginScreenEmail from "./pages/auth/loginscreen/LoginScreenEmail.js";
import RegisterScreenAmazon from "./pages/auth/registerscreen/RegisterScreenAmazon.js";
import RedeemScreenAmazon from "./pages/auth/registerscreen/RedeemScreenAmazon.js";
import LoginScreenAmazon from "./pages/auth/loginscreen/LoginScreenAmazon.js";
import RegisterScreenWithInfo from "./pages/auth/registerscreen/RegisterScreenWithInfo.js";
import LoginScreenFromInfo from "./pages/auth/loginscreen/LoginScreenFromInfo.js";

import ProfileScreen from "./pages/account/settings/profile/ProfileScreen";
import EditProfileScreen from "./pages/account/settings/profile/EditProfileScreen";
import DeleteAccountSuccess from "./pages/account/settings/profile/DeleteAccountSuccess";
import SubscriptionScreen from "./pages/account/subscription/SubscriptionScreen.js";
import ThankYouScreen from "./pages/account/subscription/ThankYouScreen.js";

import SideNavbar from "./components/side-navbar/SideNavbar";
import Header from "./components/header/Header.js";
import TeacherPage from './pages/admin/teachers/TeacherPage';

import {isBeatliiEmailDomain} from "./components/forminput/validation";

class App extends React.Component {

    constructor(props) {
        super(props);
        const params = Object.fromEntries(new URLSearchParams(window.location.search).entries());

        this.state = {
            appState: "init",
            user: {name: 'test'},
            errorCode: null,
            destination: params.Navigate === "checkout" ? "checkout" : "overview"
        };

        this.checkLogin = this.checkLogin.bind(this);
        this.userLoggedIn = this.userLoggedIn.bind(this);
        this.logout = this.logout.bind(this);
        this.errorHandler = this.errorHandler.bind(this);
    }

    errorHandler(error) {
        var message;
        if (error) {
            if (error.message) {
                message = error.message;
            }
            if (error.response && error.response.data && error.response.data.message) {
                message = error.response.data.message;
            }
        } else {
            return false;
        }
        if (message === "Network Error") {
            this.setState({errorCode: "network-error"});
            return Promise.reject("network-error");
        } else if (message === "not-logged-in") {
            this.setState({appState: "login"});
        } else if (message === "apple-subscription-active" || message === "google-subscription-active") {
            this.setState({appState: "overview", errorCode: message})
        } else if (message === "token-expired") {
            const originalRequest = error.config;
            return updateAccessToken().then(() => {
                originalRequest['headers'] = {Authentication: getAccessToken()};
                return axios(originalRequest);
            })
        }

        return Promise.reject(message);
    }

    componentDidMount() {

        this.history = createBrowserHistory();
        this.unlistenHistory = this.history.listen((location, action) => {
            //console.log(action, location.pathname, location.state);
        });

        const that = this;

        this.requestInterceptor = axios.interceptors.request.use(
            (config) => {return config;}, this.errorHandler);
        this.responseInterceptor = axios.interceptors.response.use(
            (response) => {return response;}, this.errorHandler);

        window.onunhandledrejection = (e) => {return this.errorHandler(e.reason);};

        this.checkLogin(); 
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevAppState = prevState.appState;
        const {user, appState} = this.state;

        if (appState === "checkout" && prevAppState !== "checkout") {
            if (user.subscriptionSource === "None" ||
                user.subscriptionSource === "Stripe") {
                requestSubscription();
            } else {
                this.setState({appState: "overview"});
            }
        }
    }

    componentWillUnmount() {
        this.unlistenHistory();

        axios.interceptors.request.eject(this.requestInterceptor);
        axios.interceptors.response.eject(this.responseInterceptor);
    }

    checkLogin() {
        const {appState, destination} = this.state;

        if (isLoggedIn()) {
            this.setState({appState: destination});
            this.userLoggedIn();
        } else {
            this.setState({appState: "login"})
        }
    }

    userLoggedIn() {
        this.setState({appState: "init"});

        const that = this;
        const destination = this.state.destination;

        doAuthenticatedRequest("GET", "/users/self", {})
            .then(userResult => {
                that.setState({user: userResult.data, appState: destination});
            });
    }

    logout() {
        const that = this;
        doAuthenticatedRequest("POST", "/security/signout", {})
            .then(() => {
                localStorage.clear();
                that.checkLogin();
            })
    }

    render() {
        const {appState, user, destination, errorCode} = this.state;

        if (appState === "init") {
            return ( <>
                    <div className="loader-container">
                        <img src={loadingSpinner} className="loader-container-loading-spinner" alt="loading"/>
                        <p className="loader-container-text">Loading...</p>
                    </div>
                </>);
        } else if (appState === "checkout") {
            return ( <>
                <div className="loader-container">
                    <img src={loadingSpinner} className="loader-container-loading-spinner" alt="loading"/>
                    <p className="loader-container-text">Navigateing to checkout...</p>
                </div>
            </>);
        } else if (appState === "login") {
            return (
                 <BrowserRouter>
                     <Routes>
                        <Route path="/thank-you" element={ <ThankYouScreen />} />
                        <Route path="/register" element={ <RegisterScreenWithInfo callback={this.userLoggedIn} />} />
                        <Route path="/login" element={ <LoginScreenFromInfo callback={this.userLoggedIn} />} />
                        <Route path="/amazon/register-de" element={ <RegisterScreenAmazon language={"de"} />} />
                        <Route path="/amazon/register" element={ <RegisterScreenAmazon language={"en"} />} />
                        <Route path="/amazon/login" element={ <LoginScreenAmazon />} />
                        <Route path="*" element={ <Navigate to="/register" />} />
                     </Routes>
                </BrowserRouter>
            );
        } else if (appState === "overview") {
            return (
                <BrowserRouter>
                    <nav>
                        <Header user={user} logout={this.logout} history={this.history} />
                        <SideNavbar logout={this.logout} history={this.history} user={user}/>
                    </nav>
                    <Routes>
                        <Route path="/thank-you" element={<ThankYouScreen />} />
                        <Route path="/account/subscription" element={ <SubscriptionScreen user={user}/>} />
                        <Route path="/account/profile" element={ <ProfileScreen user={user} />} />
                        <Route
                          path="/account/teachers/*"
                          element={
                            user && isBeatliiEmailDomain(user.email)
                              ? <TeacherPage />
                              : <Navigate to="/account/subscription" />
                          }
                        />
                        <Route path="/account/profile/edit" element={ <EditProfileScreen />} />
                        <Route path="/account/delete-success" element={ <DeleteAccountSuccess/>} />
                        <Route path="/amazon/register" element={ <RedeemScreenAmazon />} />
                        <Route path="*" element={ <Navigate to="/account/subscription" />} />
                    </Routes>
                </BrowserRouter>
            );
        }
       
    }
}


export default App;