import React, { useState } from 'react';

const CommissionTeacherList = ({ teachers, onViewDetails, onDeleteTeacher }) => {
  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const sortedTeachers = [...teachers].sort((a, b) => {
    if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
    if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  if (teachers.length === 0) {
    return <div className="no-teachers">No commission teachers found</div>;
  }

  return (
    <table className="teacher-table">
      <thead>
        <tr>
          <th onClick={() => handleSort('name')}>
            Name {sortField === 'name' && (sortDirection === 'asc' ? '▲' : '▼')}
          </th>
          <th onClick={() => handleSort('email')}>
            Email {sortField === 'email' && (sortDirection === 'asc' ? '▲' : '▼')}
          </th>
          <th onClick={() => handleSort('promoCode')}>
            Promo Code {sortField === 'promoCode' && (sortDirection === 'asc' ? '▲' : '▼')}
          </th>
          <th onClick={() => handleSort('trialDays')}>
            Trial Days {sortField === 'trialDays' && (sortDirection === 'asc' ? '▲' : '▼')}
          </th>
          <th onClick={() => handleSort('commissionPercentage')}>
            Commission {sortField === 'commissionPercentage' && (sortDirection === 'asc' ? '▲' : '▼')}
          </th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {sortedTeachers.map(teacher => (
          <tr key={teacher.id}>
            <td>{teacher.name}</td>
            <td>{teacher.email}</td>
            <td>
              {teacher.promoCode ? (
                <a
                  href={`https://dashboard.stripe.com/promotion_codes/${teacher.promoCode}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: '#3b82f6',
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}
                >
                  {teacher.promoCode}
                </a>
              ) : (
                "-"
              )}
            </td>
            <td>{teacher.trialDays || 0}</td>
            <td>{teacher.commissionPercentage ? (Math.round(teacher.commissionPercentage * 100)) + "%" : '0%'}</td>
            <td className="actions">
              <button className="btn btn-view" onClick={() => onViewDetails(teacher.id)}>View</button>
              {teacher.studentCount === 0 && (
                <button
                  className="btn btn-delete"
                  onClick={() => onDeleteTeacher(teacher.id)}
                  style={{
                    backgroundColor: '#ef4444',
                    color: 'white',
                    marginLeft: '8px',
                    border: 'none',
                    borderRadius: '4px',
                    padding: '8px 16px',
                    cursor: 'pointer'
                  }}
                >
                  Delete
                </button>
              )}

            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CommissionTeacherList;