    import React, { useState, useEffect } from 'react';
    import { Link, useNavigate } from 'react-router-dom';
    import { doAuthenticatedRequest } from '../../../api/requests';
    import { handleError } from '../../../api/errorHandling';
    import SubscriptionTeacherList from './SubscriptionTeacherList';
    import CommissionTeacherList from './CommissionTeacherList';
    import './TeacherList.css';

    const TeacherListComponent = () => {
      const [teachers, setTeachers] = useState([]);
      const [loading, setLoading] = useState(true);
      const [error, setError] = useState(null);
      const [filterText, setFilterText] = useState('');
      const [activeTab, setActiveTab] = useState('SUBSCRIPTION'); // Default active tab
      const navigate = useNavigate();

      useEffect(() => {
        fetchTeachers();
      }, []);

      const fetchTeachers = async () => {
        setLoading(true);
        try {
          const response = await doAuthenticatedRequest('GET', '/teachers');
          setTeachers(response.data);
          setLoading(false);
        } catch (error) {
          console.log(error);
          setError(handleError(error));
          setLoading(false);
        }
      };

      const handleFilter = (e) => {
        setFilterText(e.target.value);
      };

      const filteredTeachers = teachers.filter(teacher =>
        teacher.name?.toLowerCase().includes(filterText.toLowerCase()) ||
        teacher.email?.toLowerCase().includes(filterText.toLowerCase())
      );

      // Split teachers by monetization model
      const subscriptionTeachers = filteredTeachers.filter(
        teacher => teacher.monetizationModel === 'SUBSCRIPTION'
      );

      const commissionTeachers = filteredTeachers.filter(
        teacher => teacher.monetizationModel === 'COMMISSION'
      );

    const handleDeleteTeacher = async (teacherId) => {
      if (window.confirm("Are you sure you want to delete this teacher?")) {
        try {
          await doAuthenticatedRequest('DELETE', `/teachers/${teacherId}`);
          setTeachers(prevTeachers => prevTeachers.filter(t => t.id !== teacherId));
        } catch (error) {
          console.error("Failed to delete teacher:", error);
          alert("Failed to delete teacher. Please try again.");
        }
      }
    };

      const viewTeacherDetails = (id) => {
        navigate(`/account/teachers/${id}`);
      };

      return (
        <div className="teacher-list-container">
          <div className="teacher-list-header">
            <h2>All Teachers</h2>
            <Link to="/account/teachers/create" className="btn btn-primary">Create New Teacher</Link>
          </div>

          <div className="filter-container">
            <input
              type="text"
              placeholder="Filter by name or email"
              value={filterText}
              onChange={handleFilter}
              className="filter-input"
            />
          </div>

          {loading ? (
            <div className="loading">Loading teachers...</div>
          ) : error ? (
            <div className="error-message">{error}</div>
          ) : (
            <>
              <div className="tabs">
                <button
                  className={`tab-button ${activeTab === 'SUBSCRIPTION' ? 'active' : ''}`}
                  onClick={() => setActiveTab('SUBSCRIPTION')}
                >
                  Subscription Teachers ({subscriptionTeachers.length})
                </button>
                <button
                  className={`tab-button ${activeTab === 'COMMISSION' ? 'active' : ''}`}
                  onClick={() => setActiveTab('COMMISSION')}
                >
                  Commission Teachers ({commissionTeachers.length})
                </button>
              </div>

              <div className="tab-content">
                {activeTab === 'SUBSCRIPTION' && (
                  <SubscriptionTeacherList
                    teachers={subscriptionTeachers}
                    onViewDetails={viewTeacherDetails}
                    onDeleteTeacher={handleDeleteTeacher}
                  />
                )}
                {activeTab === 'COMMISSION' && (
                  <CommissionTeacherList
                    teachers={commissionTeachers}
                    onViewDetails={viewTeacherDetails}
                    onDeleteTeacher={handleDeleteTeacher}
                  />
                )}
              </div>
            </>
          )}
        </div>
      );
    };

    export default TeacherListComponent;