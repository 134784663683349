export const passwordValidation = (password) => {
    return password.length >= 8;
};

export const emailValidation = (email) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
};

export const nameValidation = (name) => {
    return /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð ,.'-]{1,50}$/u.test(name);
}

export const promoCodeValidation = (promocode) => {
    return /^([0-9]{8}|[0-9]{6})$/u.test(promocode);
}

export const bodyValidation = (body) => {
    return body !== null && body !== undefined && body !== "";
}

export const usernameValidation = (username) => {
    return /^[a-zA-Z0-9_]{1,20}$/u.test(username);
}

export const isValid = (validations) => {
    return !Object.values(validations).includes(false)
}

export const isBeatliiEmailDomain = (email) => {
  return email && email.toLowerCase().endsWith('@beatlii.com');
};
