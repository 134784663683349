import './sidenavbar.scss'
import {NavLink} from "react-router-dom";
import FreedrumLogo from '../../assets/images/freedrum-logo.svg'
import {Navigate, Link} from "react-router-dom";
import {MdClose} from "react-icons/md";
import {useState} from 'react';
import {logOut} from "../../api/userRequests";
import { HiExternalLink } from "react-icons/hi";
import {isBeatliiEmailDomain} from "../../components/forminput/validation";

function SideNavbar({logout, history, user}) {

    return (
        <div id="SideNavbar-content">
            <div className="SideNavbar-container">
                <span className="SideNavbar-container__closeBtn" onClick={mobileNavHandler}><MdClose size={16}/></span>
                <div className="SideNavbar-beatliiLogo">
                    <Link to="">
                        <img src={FreedrumLogo} alt="Freedrum logo"/>
                    </Link>
                </div>
                <NavLink to="/account/subscription"
                        activeclassname="SideNavbar-active">
                    <div className="SideNavbar-item">
                        <p className="SideNavbar-mainItem">Subscription</p>
                    </div>
                </NavLink>

                <NavLink to="/account/profile"
                        activeclassname="SideNavbar-active">
                    <div className="SideNavbar-item">
                        <p className="SideNavbar-mainItem">Profile</p>
                    </div>
                </NavLink>

                {user && isBeatliiEmailDomain(user.email) ? (
                  <NavLink to="/account/teachers" activeclassname="SideNavbar-active">
                    <div className="SideNavbar-item">
                      <p className="SideNavbar-mainItem">Teachers</p>
                    </div>
                  </NavLink>
                ) : null}

                <NavLink to={{pathname:"https://beatlii.com/pages/faq"}} target="_blank"
                        activeclassname="SideNavbar-active">
                    <div className="SideNavbar-item">
                        <p className="SideNavbar-mainItem">FAQ <HiExternalLink className="text-icon" size="15px" /></p>
                    </div>
                </NavLink>

                <NavLink to={{pathname:"https://beatlii.com/pages/setup-page"}} target="_blank"
                        activeclassname="SideNavbar-active">
                    <div className="SideNavbar-item">
                        <p className="SideNavbar-mainItem">Tutorials <HiExternalLink className="text-icon" size="15px" /></p>
                    </div>
                </NavLink>

                <button className="SideNavbar-logoutBtn" onClick={logout}>
                    Log out
                </button>

            </div>
            <div className="SideNavbar-overlay" onClick={mobileNavHandler}/>
        </div>
    );
}

function mobileNavHandler() {
    let element = document.getElementById("SideNavbar-content");
    if (element.className === 'SideNavbar-content-visible') {
        element.className = '';
    } else {
        element.className = 'SideNavbar-content-visible';
    }
}

export default SideNavbar;